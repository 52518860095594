<script setup lang="ts">
import type { jobs, clients, locations } from "@prisma/client";
import titleCase from "lodash/startCase";
import type { JobWithClientAndLocations } from "@/types";

const route = useRoute();

const { routeTypes, states } = useAppConfig();

const props = defineProps<{
  job: JobWithClientAndLocations;
  loadLocations?: boolean;
}>();

const searchedState = computed(() => {
  if (route.params.state) {
    return maybeArray(
      maybeArray(
        states.find(
          (s) =>
            s[1].toLowerCase() === (route.params.state as string).toLowerCase()
        )
      ).at(0)
    );
  }
  const state =
    route.query.state ||
    ((route.query?.location?.toString() || ""))?.split(",").at(1) ||
    route.query.location;
  return maybeArray(state);
});

const searchedCity = computed(() => {
  const city =
    route.query.city ||
    ((route.query?.location?.toString() || ""))?.split(",").at(0) ||
    route.query.location;
  return maybeArray(city);
});

const locations = computed(() => {
  if (!props.job?.locations) return [];
  return props.job.locations.sort((a, b) => {
    const searchedCityNorm = searchedCity.value.map((city) =>
      city?.toString().toLowerCase()
    );

    if (searchedCityNorm.includes(a?.city.toLowerCase())) return -1;
    if (searchedCityNorm.includes(b?.city.toLowerCase())) return 1;
    if (searchedState.value.includes(a?.statecode)) return -1;
    if (searchedState.value.includes(b?.statecode)) return 1;
    return 0;
  });
});

const url = computed(() => {
  const routeRecord = jobObjectToRouteRecord(props.job);

  const queryCloned = JSON.parse(JSON.stringify(route.query));
  delete queryCloned.keywords;
  delete queryCloned.include;
  delete queryCloned.perPage;
  delete queryCloned.radius;
  delete queryCloned.published;
  delete queryCloned.clientId;
  delete queryCloned.driverType;
  delete queryCloned.freightTypes;
  delete queryCloned.routeTypes;

  return {
    ...routeRecord,
    query: {
      ...queryCloned,
      city: locations.value?.at(0)?.city,
      state: locations.value?.at(0)?.statecode,
    },
  };
});

const { primaryColor } = usePrimaryColor();
const primaryWithAlpha = computed(() => `${primaryColor.value}10`);
</script>
<template>
  <NuxtLink :to="url">
    <div class="p-6 bg-white rounded-md shadow group bg-primary-hover-group dark:shadow-gray-700 dark:bg-slate-900">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <div
            class="flex items-center justify-center bg-white rounded-md shadow w-14 h-14 dark:bg-slate-900 dark:shadow-gray-700">
            <NuxtImg :src="job.client.logo || '/no-image-placeholder.png'" width="150"
              class="w-12 h-12 rounded bg-[#efefef]" :alt="job.client.name || 'No image available'" />
          </div>

          <div class="ms-3">
            <span class="block text-[16px] font-semibold text-hover-primary transition-all duration-500">{{
              job.client.name }}
            </span>
            <div class="flex items-center text-xs text-slate-400">
              <IconMapOutline class="w-4 h-4 mr-1" />
              {{
                routeTypes
                  .filter((t) => job.routeTypes.includes(t.value))
                  .map((t) => t.label)
                  .join(", ")
              }}
            </div>
          </div>
        </div>

        <span
          class="inline-block text-xs font-semibold transition-all duration-500 rounded-full bg-primary-10 txt-primary group-hover:text-white whitespace-nowrap">
          <IconArrowRightCircle class="inline w-8" />
        </span>
      </div>

      <div class="mt-2">
        <h2 class="text-lg font-semibold transition-all duration-500 text-hover-primary">
          {{ job.title }}
        </h2>
        <JobLocationLabel :job="job" :loadLocations="loadLocations" />
      </div>

      <div class="mt-2">
        <span
          class="inline-block px-4 mt-2 text-xs font-medium text-orange-500 transition-all duration-500 rounded-full bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 me-1">
          <IconTruck class="inline w-4 mr-1" />
          {{
            titleCase(job.driverType?.replaceAll("_", " ").toLowerCase())
          }}
        </span>
        <span v-if="job.salary"
          class="inline-block px-4 mt-2 text-xs font-medium text-purple-600 transition-all duration-500 rounded-full bg-purple-600/5 hover:bg-purple-600/20 dark:bg-purple-600/10 hover:dark:bg-purple-600/30 me-1">
          <IconCurrencyDollar class="inline w-4" />

          {{ job.salary }}
        </span>
        <span
          class="inline-block px-4 mt-2 text-xs font-medium transition-all duration-500 rounded-full bg-emerald-600/5 hover:bg-emerald-600/20 dark:bg-emerald-600/10 hover:dark:bg-emerald-600/30 text-emerald-600">
          <IconAdjustmentsHorizontal class="inline w-4 mr-1" />
          {{ job.experience }}
        </span>
      </div>
    </div>
  </NuxtLink>
</template>
<style scoped>
.text-hover-primary:hover,
.txt-primary {
  color: v-bind(primaryColor);
}

.bg-primary-10 {
  background-color: v-bind(primaryWithAlpha);
}

.bg-primary-hover-group:hover .bg-primary-10 {
  background-color: v-bind(primaryColor);
}
</style>
